@font-face {
  font-family: "Dela";
  src: url("../../fonts/Dela_Gothic_One/DelaGothicOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.backgroundContainer {
  width: 100%;
  min-height: 100vh;
  background-color: black;
  color: white;
  background-size: cover;
  background-position: center;
}

.navButtons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.navButton {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navButton:hover {
  background-color: white;
  color: black;
}

.gallery {
  padding: 5rem;
}

.images {
  text-align: center;
}

.padding {
  padding-bottom: 74rem;
}

@media (max-width: 768px) {
  .navButtons {
    text-align: center;
    gap: 2px;
  }
  .navButton {
    font-size: 10px;
  }

  .padding {
    padding-bottom: 5rem;
  }
}
