@font-face {
  font-family: "Inter";
  src: url("../../../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
.background {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  padding: 1rem;
  font-family: "Inter";
  max-width: 350px;
  display: flex;
  text-align: center;
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
}
.background:hover {
  transform: translateY(-10px);
  background-color: rgba(0, 0, 0, 1);
  color: white;
}
.projectImage {
  max-width: 350px;
}
.text {
  text-align: left;
}
.cardLink {
  color: transparent !important;
}

@media (max-width: 768px) {
  .projectImage {
    max-width: 300px;
  }
}
